.notification-blink {
  animation: notification-blink 3s infinite;
}

@keyframes notification-blink {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  25%,
  75% {
    transform: scale(0.8);
  }
}

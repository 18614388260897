/* Button Loader 1*/
.btn-loader-1 {
  width: 20px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, #fff) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg 20deg,
      #0000 21deg 36deg
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(100% - var(--b) - 1px),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: btn-loader-1 1s infinite steps(10);
}
@keyframes btn-loader-1 {
  to {
    transform: rotate(1turn);
  }
}

/* Full Page Loader */
.page-loader {
  border: 5px solid rgba(210, 210, 210, 0.722);
  border-top: 5px solid #bf7eff;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  animation: page-loader 700ms linear infinite;
}

@keyframes page-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border: 1px solid #6952f4;
}

::-webkit-scrollbar-track {
  background: rgb(228, 228, 228);
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(89.18deg, #bf7eff -10.78%, #6952f4 121.28%);
  border: 1px solid #6952f4;
}

/* .active {
  background-color: #bf7eff !important;
  color: white !important;
} */
